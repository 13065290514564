export const DEPLOYMENT_ENVIRONMENT = process.env.GATSBY_DEPLOYMENT_ENVIRONMENT;
export const GEN_AI_BOT_ID = process.env.GATSBY_GEN_AI_BOT_ID;
export const TRACK_TRANSACTION_URL = process.env.GATSBY_TRACK_TRANSACTION_URL;
export const CONTACT_SALES_URL = process.env.GATSBY_CONTACT_SALES_URL;
export const MERCHANT_HELP_URL = process.env.GATSBY_MERCHANT_HELP_URL;
export const COMMON_ADMIN_URL = process.env.GATSBY_COMMON_ADMIN_URL;
export const VERIFICATION_URL = process.env.GATSBY_VERIFICATION_URL;
export const FRESHDESK_URL = process.env.GATSBY_FRESHDESK_URL;
export const PAYTONUMBER_API_URL = process.env.GATSBY_PAYTONUMBER_API_URL;
export const FETCH_JOBS_API_URL = process.env.GATSBY_GET_JOBS_API_URL;
export const PAYTONUMBER_EMAIL = process.env.GATSBY_PAYTONUMBER_EMAIL;
export const MERCHANT_DASHBOARD_SIGNUP_URL =
  process.env.GATSBY_MERCHANT_DASHBOARD_SIGNUP;
export const PARTNER_SIGNUP = process.env.GATSBY_PARTNER_SIGNUP;
export const FETCH_REGION_API_URL = process.env.GATSBY_GET_REGION_API_URL;
export const RECAPTCHA_SITE_KEY = process.env.GATSBY_SITE_KEY;
export const ONE_CLICK_CHECKOUT_API_URL = process.env.GATSBY_OCC_API_URL;
export const OCC_DEPLOYMENT_ENVIRONMENT =
  process.env.GATSBY_OCC_DEPLOYMENT_ENVIRONMENT;
