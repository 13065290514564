exports.components = {
  "component---src-pages-aadhaar-verification-jsx": () => import("./../../../src/pages/aadhaar-verification.jsx" /* webpackChunkName: "component---src-pages-aadhaar-verification-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-accept-international-payments-jsx": () => import("./../../../src/pages/accept-international-payments.jsx" /* webpackChunkName: "component---src-pages-accept-international-payments-jsx" */),
  "component---src-pages-api-banking-india-jsx": () => import("./../../../src/pages/api-banking-india.jsx" /* webpackChunkName: "component---src-pages-api-banking-india-jsx" */),
  "component---src-pages-bank-account-verification-jsx": () => import("./../../../src/pages/bank-account-verification.jsx" /* webpackChunkName: "component---src-pages-bank-account-verification-jsx" */),
  "component---src-pages-banking-as-a-service-jsx": () => import("./../../../src/pages/banking-as-a-service.jsx" /* webpackChunkName: "component---src-pages-banking-as-a-service-jsx" */),
  "component---src-pages-bnpl-plus-jsx": () => import("./../../../src/pages/bnpl-plus.jsx" /* webpackChunkName: "component---src-pages-bnpl-plus-jsx" */),
  "component---src-pages-buy-now-pay-later-jsx": () => import("./../../../src/pages/buy-now-pay-later.jsx" /* webpackChunkName: "component---src-pages-buy-now-pay-later-jsx" */),
  "component---src-pages-card-issuance-jsx": () => import("./../../../src/pages/card-issuance.jsx" /* webpackChunkName: "component---src-pages-card-issuance-jsx" */),
  "component---src-pages-card-tokenization-jsx": () => import("./../../../src/pages/card-tokenization.jsx" /* webpackChunkName: "component---src-pages-card-tokenization-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-case-study-jsx": () => import("./../../../src/pages/case-study.jsx" /* webpackChunkName: "component---src-pages-case-study-jsx" */),
  "component---src-pages-cashgram-jsx": () => import("./../../../src/pages/cashgram.jsx" /* webpackChunkName: "component---src-pages-cashgram-jsx" */),
  "component---src-pages-connected-banking-jsx": () => import("./../../../src/pages/connected-banking.jsx" /* webpackChunkName: "component---src-pages-connected-banking-jsx" */),
  "component---src-pages-contact-sales-jsx": () => import("./../../../src/pages/contact-sales.jsx" /* webpackChunkName: "component---src-pages-contact-sales-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-customer-resolution-guide-jsx": () => import("./../../../src/pages/customer-resolution-guide.jsx" /* webpackChunkName: "component---src-pages-customer-resolution-guide-jsx" */),
  "component---src-pages-customer-reward-scheme-terms-and-conditions-jsx": () => import("./../../../src/pages/customer-reward-scheme-terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-customer-reward-scheme-terms-and-conditions-jsx" */),
  "component---src-pages-debit-card-emi-jsx": () => import("./../../../src/pages/debit-card-emi.jsx" /* webpackChunkName: "component---src-pages-debit-card-emi-jsx" */),
  "component---src-pages-demo-one-click-checkout-demo-jsx": () => import("./../../../src/pages/demo/one-click-checkout-demo.jsx" /* webpackChunkName: "component---src-pages-demo-one-click-checkout-demo-jsx" */),
  "component---src-pages-developer-code-of-conduct-jsx": () => import("./../../../src/pages/developer-code-of-conduct.jsx" /* webpackChunkName: "component---src-pages-developer-code-of-conduct-jsx" */),
  "component---src-pages-easy-split-split-payment-gateway-jsx": () => import("./../../../src/pages/easy-split/split-payment-gateway.jsx" /* webpackChunkName: "component---src-pages-easy-split-split-payment-gateway-jsx" */),
  "component---src-pages-embedded-lending-jsx": () => import("./../../../src/pages/embedded-lending.jsx" /* webpackChunkName: "component---src-pages-embedded-lending-jsx" */),
  "component---src-pages-embedded-payments-jsx": () => import("./../../../src/pages/embedded-payments.jsx" /* webpackChunkName: "component---src-pages-embedded-payments-jsx" */),
  "component---src-pages-en-report-issue-jsx": () => import("./../../../src/pages/en/report-issue.jsx" /* webpackChunkName: "component---src-pages-en-report-issue-jsx" */),
  "component---src-pages-en-report-socialmedia-issue-jsx": () => import("./../../../src/pages/en/report-socialmedia-issue.jsx" /* webpackChunkName: "component---src-pages-en-report-socialmedia-issue-jsx" */),
  "component---src-pages-enach-jsx": () => import("./../../../src/pages/enach.jsx" /* webpackChunkName: "component---src-pages-enach-jsx" */),
  "component---src-pages-escrow-account-jsx": () => import("./../../../src/pages/escrow-account.jsx" /* webpackChunkName: "component---src-pages-escrow-account-jsx" */),
  "component---src-pages-events-jsx": () => import("./../../../src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-freelancer-payment-gateway-jsx": () => import("./../../../src/pages/freelancer-payment-gateway.jsx" /* webpackChunkName: "component---src-pages-freelancer-payment-gateway-jsx" */),
  "component---src-pages-genai-redirect-jsx": () => import("./../../../src/pages/genai-redirect.jsx" /* webpackChunkName: "component---src-pages-genai-redirect-jsx" */),
  "component---src-pages-grievance-redressal-policy-jsx": () => import("./../../../src/pages/grievance-redressal-policy.jsx" /* webpackChunkName: "component---src-pages-grievance-redressal-policy-jsx" */),
  "component---src-pages-growth-accept-international-payments-jsx": () => import("./../../../src/pages/growth/accept-international-payments.jsx" /* webpackChunkName: "component---src-pages-growth-accept-international-payments-jsx" */),
  "component---src-pages-growth-contact-form-jsx": () => import("./../../../src/pages/growth/contact-form.jsx" /* webpackChunkName: "component---src-pages-growth-contact-form-jsx" */),
  "component---src-pages-growth-merchant-instructions-jsx": () => import("./../../../src/pages/growth/merchant-instructions.jsx" /* webpackChunkName: "component---src-pages-growth-merchant-instructions-jsx" */),
  "component---src-pages-growth-one-click-checkout-jsx": () => import("./../../../src/pages/growth/one-click-checkout.jsx" /* webpackChunkName: "component---src-pages-growth-one-click-checkout-jsx" */),
  "component---src-pages-growth-payment-gateway-jsx": () => import("./../../../src/pages/growth/payment-gateway.jsx" /* webpackChunkName: "component---src-pages-growth-payment-gateway-jsx" */),
  "component---src-pages-growth-receive-international-payments-jsx": () => import("./../../../src/pages/growth/receive-international-payments.jsx" /* webpackChunkName: "component---src-pages-growth-receive-international-payments-jsx" */),
  "component---src-pages-gst-verification-jsx": () => import("./../../../src/pages/gst-verification.jsx" /* webpackChunkName: "component---src-pages-gst-verification-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-instant-settlements-jsx": () => import("./../../../src/pages/instant-settlements.jsx" /* webpackChunkName: "component---src-pages-instant-settlements-jsx" */),
  "component---src-pages-international-payment-gateway-jsx": () => import("./../../../src/pages/international-payment-gateway.jsx" /* webpackChunkName: "component---src-pages-international-payment-gateway-jsx" */),
  "component---src-pages-international-payments-international-wire-transfer-jsx": () => import("./../../../src/pages/international-payments/international-wire-transfer.jsx" /* webpackChunkName: "component---src-pages-international-payments-international-wire-transfer-jsx" */),
  "component---src-pages-international-payments-international-wire-transfer-yes-bank-cashfree-jsx": () => import("./../../../src/pages/international-payments/international-wire-transfer/yes-bank-cashfree.jsx" /* webpackChunkName: "component---src-pages-international-payments-international-wire-transfer-yes-bank-cashfree-jsx" */),
  "component---src-pages-international-payments-outward-remittance-jsx": () => import("./../../../src/pages/international-payments/outward-remittance.jsx" /* webpackChunkName: "component---src-pages-international-payments-outward-remittance-jsx" */),
  "component---src-pages-kyc-verification-jsx": () => import("./../../../src/pages/kyc-verification.jsx" /* webpackChunkName: "component---src-pages-kyc-verification-jsx" */),
  "component---src-pages-nbfc-digital-lending-solutions-jsx": () => import("./../../../src/pages/nbfc-digital-lending-solutions.jsx" /* webpackChunkName: "component---src-pages-nbfc-digital-lending-solutions-jsx" */),
  "component---src-pages-news-room-jsx": () => import("./../../../src/pages/news-room.jsx" /* webpackChunkName: "component---src-pages-news-room-jsx" */),
  "component---src-pages-pan-verification-jsx": () => import("./../../../src/pages/PAN-verification.jsx" /* webpackChunkName: "component---src-pages-pan-verification-jsx" */),
  "component---src-pages-partner-integration-jsx": () => import("./../../../src/pages/partner-integration.jsx" /* webpackChunkName: "component---src-pages-partner-integration-jsx" */),
  "component---src-pages-payment-forms-jsx": () => import("./../../../src/pages/payment-forms.jsx" /* webpackChunkName: "component---src-pages-payment-forms-jsx" */),
  "component---src-pages-payment-gateway-charges-jsx": () => import("./../../../src/pages/payment-gateway-charges.jsx" /* webpackChunkName: "component---src-pages-payment-gateway-charges-jsx" */),
  "component---src-pages-payment-gateway-india-jsx": () => import("./../../../src/pages/payment-gateway-india.jsx" /* webpackChunkName: "component---src-pages-payment-gateway-india-jsx" */),
  "component---src-pages-payment-links-jsx": () => import("./../../../src/pages/payment-links.jsx" /* webpackChunkName: "component---src-pages-payment-links-jsx" */),
  "component---src-pages-payment-pages-jsx": () => import("./../../../src/pages/payment-pages.jsx" /* webpackChunkName: "component---src-pages-payment-pages-jsx" */),
  "component---src-pages-paymentservices-tnc-jsx": () => import("./../../../src/pages/paymentservices_tnc.jsx" /* webpackChunkName: "component---src-pages-paymentservices-tnc-jsx" */),
  "component---src-pages-payouts-jsx": () => import("./../../../src/pages/payouts.jsx" /* webpackChunkName: "component---src-pages-payouts-jsx" */),
  "component---src-pages-preauthorization-jsx": () => import("./../../../src/pages/preauthorization.jsx" /* webpackChunkName: "component---src-pages-preauthorization-jsx" */),
  "component---src-pages-presskit-jsx": () => import("./../../../src/pages/presskit.jsx" /* webpackChunkName: "component---src-pages-presskit-jsx" */),
  "component---src-pages-privacypolicy-jsx": () => import("./../../../src/pages/privacypolicy.jsx" /* webpackChunkName: "component---src-pages-privacypolicy-jsx" */),
  "component---src-pages-recurring-payment-jsx": () => import("./../../../src/pages/recurring-payment.jsx" /* webpackChunkName: "component---src-pages-recurring-payment-jsx" */),
  "component---src-pages-report-an-issue-jsx": () => import("./../../../src/pages/report-an-issue.jsx" /* webpackChunkName: "component---src-pages-report-an-issue-jsx" */),
  "component---src-pages-settlement-and-reconciliation-guide-jsx": () => import("./../../../src/pages/settlement-and-reconciliation-guide.jsx" /* webpackChunkName: "component---src-pages-settlement-and-reconciliation-guide-jsx" */),
  "component---src-pages-settlement-holidays-jsx": () => import("./../../../src/pages/settlement-holidays.jsx" /* webpackChunkName: "component---src-pages-settlement-holidays-jsx" */),
  "component---src-pages-shopify-payment-gateway-india-jsx": () => import("./../../../src/pages/shopify-payment-gateway-india.jsx" /* webpackChunkName: "component---src-pages-shopify-payment-gateway-india-jsx" */),
  "component---src-pages-shopify-signup-jsx": () => import("./../../../src/pages/shopify-signup.jsx" /* webpackChunkName: "component---src-pages-shopify-signup-jsx" */),
  "component---src-pages-soft-pos-jsx": () => import("./../../../src/pages/soft-pos.jsx" /* webpackChunkName: "component---src-pages-soft-pos-jsx" */),
  "component---src-pages-solutions-ecommerce-payment-gateway-jsx": () => import("./../../../src/pages/solutions/ecommerce-payment-gateway.jsx" /* webpackChunkName: "component---src-pages-solutions-ecommerce-payment-gateway-jsx" */),
  "component---src-pages-solutions-education-jsx": () => import("./../../../src/pages/solutions/education.jsx" /* webpackChunkName: "component---src-pages-solutions-education-jsx" */),
  "component---src-pages-startups-jsx": () => import("./../../../src/pages/startups.jsx" /* webpackChunkName: "component---src-pages-startups-jsx" */),
  "component---src-pages-support-jsx": () => import("./../../../src/pages/support.jsx" /* webpackChunkName: "component---src-pages-support-jsx" */),
  "component---src-pages-thanks-jsx": () => import("./../../../src/pages/thanks.jsx" /* webpackChunkName: "component---src-pages-thanks-jsx" */),
  "component---src-pages-tnc-free-tools-jsx": () => import("./../../../src/pages/tnc/free-tools.jsx" /* webpackChunkName: "component---src-pages-tnc-free-tools-jsx" */),
  "component---src-pages-tnc-gstin-free-tool-jsx": () => import("./../../../src/pages/tnc/gstin-free-tool.jsx" /* webpackChunkName: "component---src-pages-tnc-gstin-free-tool-jsx" */),
  "component---src-pages-tnc-jsx": () => import("./../../../src/pages/tnc.jsx" /* webpackChunkName: "component---src-pages-tnc-jsx" */),
  "component---src-pages-track-payment-status-embed-jsx": () => import("./../../../src/pages/track-payment-status/embed.jsx" /* webpackChunkName: "component---src-pages-track-payment-status-embed-jsx" */),
  "component---src-pages-track-payment-status-issue-jsx": () => import("./../../../src/pages/track-payment-status/issue.jsx" /* webpackChunkName: "component---src-pages-track-payment-status-issue-jsx" */),
  "component---src-pages-track-payment-status-list-jsx": () => import("./../../../src/pages/track-payment-status/list.jsx" /* webpackChunkName: "component---src-pages-track-payment-status-list-jsx" */),
  "component---src-pages-track-payment-status-status-jsx": () => import("./../../../src/pages/track-payment-status/status.jsx" /* webpackChunkName: "component---src-pages-track-payment-status-status-jsx" */),
  "component---src-pages-track-payment-status-verify-jsx": () => import("./../../../src/pages/track-payment-status/verify.jsx" /* webpackChunkName: "component---src-pages-track-payment-status-verify-jsx" */),
  "component---src-pages-upi-autopay-jsx": () => import("./../../../src/pages/upi-autopay.jsx" /* webpackChunkName: "component---src-pages-upi-autopay-jsx" */),
  "component---src-pages-upi-payment-gateway-jsx": () => import("./../../../src/pages/upi-payment-gateway.jsx" /* webpackChunkName: "component---src-pages-upi-payment-gateway-jsx" */),
  "component---src-pages-upi-qr-code-jsx": () => import("./../../../src/pages/upi-qr-code.jsx" /* webpackChunkName: "component---src-pages-upi-qr-code-jsx" */),
  "component---src-pages-user-response-jsx": () => import("./../../../src/pages/user-response.jsx" /* webpackChunkName: "component---src-pages-user-response-jsx" */),
  "component---src-pages-vendor-payments-jsx": () => import("./../../../src/pages/vendor-payments.jsx" /* webpackChunkName: "component---src-pages-vendor-payments-jsx" */),
  "component---src-pages-virtual-payment-address-jsx": () => import("./../../../src/pages/virtual-payment-address.jsx" /* webpackChunkName: "component---src-pages-virtual-payment-address-jsx" */),
  "component---src-pages-white-label-payment-gateway-jsx": () => import("./../../../src/pages/white-label-payment-gateway.jsx" /* webpackChunkName: "component---src-pages-white-label-payment-gateway-jsx" */),
  "component---src-pages-whitepapers-optimizing-transaction-success-rate-jsx": () => import("./../../../src/pages/whitepapers/optimizing-transaction-success-rate.jsx" /* webpackChunkName: "component---src-pages-whitepapers-optimizing-transaction-success-rate-jsx" */),
  "component---src-pages-wix-payment-gateway-jsx": () => import("./../../../src/pages/wix-payment-gateway.jsx" /* webpackChunkName: "component---src-pages-wix-payment-gateway-jsx" */),
  "component---src-pages-working-capital-loan-jsx": () => import("./../../../src/pages/working-capital-loan.jsx" /* webpackChunkName: "component---src-pages-working-capital-loan-jsx" */),
  "component---src-pages-wp-template-preview-jsx": () => import("./../../../src/pages/wp-template-preview.jsx" /* webpackChunkName: "component---src-pages-wp-template-preview-jsx" */),
  "component---src-templates-case-study-jsx": () => import("./../../../src/templates/case-study.jsx" /* webpackChunkName: "component---src-templates-case-study-jsx" */),
  "component---src-templates-guide-jsx": () => import("./../../../src/templates/guide.jsx" /* webpackChunkName: "component---src-templates-guide-jsx" */),
  "component---src-templates-landing-page-template-jsx": () => import("./../../../src/templates/landingPageTemplate.jsx" /* webpackChunkName: "component---src-templates-landing-page-template-jsx" */),
  "component---src-templates-learning-jsx": () => import("./../../../src/templates/learning.jsx" /* webpackChunkName: "component---src-templates-learning-jsx" */),
  "component---src-templates-paid-ads-page-template-jsx": () => import("./../../../src/templates/paidAdsPageTemplate.jsx" /* webpackChunkName: "component---src-templates-paid-ads-page-template-jsx" */),
  "component---src-templates-press-release-doc-jsx": () => import("./../../../src/templates/pressReleaseDoc.jsx" /* webpackChunkName: "component---src-templates-press-release-doc-jsx" */),
  "component---src-templates-wordpress-page-template-jsx": () => import("./../../../src/templates/wordpressPageTemplate.jsx" /* webpackChunkName: "component---src-templates-wordpress-page-template-jsx" */)
}

